import axios from 'axios'
import store from '../store'
import Vue from 'vue'
import { AppVersion, OS, OSVersion } from '../deviceDetails'
import { Success } from '@/statusCodes.js'

const baseURL = process.env.VUE_APP_API_URL

const axiosInstance = axios.create({
	baseURL,
	headers: {
		OS,
		OSVersion,
		AppVersion,
		Platform: 'Web'
	},
	timeout: 30000 // 30 sec timeout
})

let isRefreshingToken = false
const refreshAndRetryQueue = []

axiosInstance.interceptors.request.use(
	(config) => {
		config.headers['Authorization'] = store.getters.getToken
		config.headers['UserId'] = store.getters.getUserId
		config.headers['ScreenName'] = store.getters.getScreenName
		config.headers['DeviceId'] = store.getters['locationStore/getLocationIpAddress'] ?? 'unknown'
		return config
	},
	(error) => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config
		// If token expired then call login api to get new token
		if (error?.response?.status === 401 && error?.response?.data?.StatusCode === 401) {
			if (!isRefreshingToken) {
				const { error: refreshError, data } = await refreshToken()

				// do logout and redirect
				if (refreshError || !data) {
					store.commit('setUserBlank')
					store.commit('setPrescriptionList', [])
					store.commit('setProfileImage', null)
					store.commit('CLOSE_DRAWER')
					localStorage.removeItem('isLogin')
					window.location.href = '/'
					return Promise.reject(error)
				}
				// do logout and redirect
				if (data.StatusCode !== Success) {
					store.commit('setUserBlank')
					store.commit('setPrescriptionList', [])
					store.commit('setProfileImage', null)
					store.commit('CLOSE_DRAWER')
					localStorage.removeItem('isLogin')
					window.location.href = '/'
					return Promise.reject(error)
				}

				error.config.headers['Authorization'] = data.Data.Token

				store.commit('setToken', data.Data.Token)

				// Retry all requests in the queue with the new token
				refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
					config.headers['Authorization'] = data.Data.Token
					axiosInstance
						.request(config)
						.then((response) => resolve(response))
						.catch((err) => reject(err))
				})

				// Clear the queue
				refreshAndRetryQueue.length = 0

				// Retry the original request
				return axiosInstance(originalRequest)
			}

			return new Promise((resolve, reject) => {
				refreshAndRetryQueue.push({ config: originalRequest, resolve, reject })
			})
		}
		return Promise.reject(error)
	}
)

export default () => {
	return axiosInstance
}

async function refreshToken() {
	isRefreshingToken = true
	try {
		const user = store.getters.getUser

		const response = await axios.post(
			`${baseURL}/Authentication/Login`,
			{
				userName: Vue.CryptoJS.AES.decrypt(user.userName, Vue.prototype.$secretKey).toString(
					Vue.CryptoJS.enc.Utf8
				),
				password: Vue.CryptoJS.AES.decrypt(user.password, Vue.prototype.$secretKey).toString(
					Vue.CryptoJS.enc.Utf8
				),
				os: OS
			},
			{
				headers: {
					OS,
					OSVersion,
					AppVersion,
					Platform: 'Web',
					UserId: store.getters.getUserId,
					ScreenName: store.getters.getScreenName,
					DeviceId: store.getters['locationStore/getLocationIpAddress'] ?? 'unknown'
				}
			}
		)

		return { data: response.data, error: undefined }
	} catch (e) {
		return { data: undefined, error: e }
	} finally {
		isRefreshingToken = false
	}
}
